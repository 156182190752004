:root {
  --color-backgroundOverlay-darkMode: rgba(0, 0, 0, 0.3);
  --color-beige-darkMode: #F5F4F1;
  --color-cardBackground-darkMode: #fff;
  --color-dimmer-darkMode: rgba(0, 0, 0, 0.36);
  --color-floatingElement-darkMode: #fff;
  --color-ghost-darkMode: #c6c6c6;
  --color-menuPopup-darkMode: #fff;
  --color-primary-darkMode: #201f2a;
  --color-red-darkMode: #fd4646;
  --color-redHover-darkMode: #dd1111;
  --color-secondary-darkMode: #9796a5;
  --color-shader-darkMode: #f5f4fe;
  --color-skeletonBase-darkMode: #ebebeb;
  --color-skeletonHighlight-darkMode: #f5f5f5;
  --color-tertiary-darkMode: #dedde5;
  --color-websiteBackground-darkMode: #F5F4F1;
  --color-white-darkMode: #fff;
}
