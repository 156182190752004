/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.backgroundColorBackgroundOverlay {
  background-color: var(--color-backgroundOverlay);
}

.backgroundColorBeige {
  background-color: var(--color-beige);
}

.backgroundColorCardBackground {
  background-color: var(--color-cardBackground);
}

.backgroundColorDimmer {
  background-color: var(--color-dimmer);
}

.backgroundColorFloatingElement {
  background-color: var(--color-floatingElement);
}

.backgroundColorGhost {
  background-color: var(--color-ghost);
}

.backgroundColorMenuPopup {
  background-color: var(--color-menuPopup);
}

.backgroundColorPrimary {
  background-color: var(--color-primary);
}

.backgroundColorRed {
  background-color: var(--color-red);
}

.backgroundColorRedHover {
  background-color: var(--color-redHover);
}

.backgroundColorSecondary {
  background-color: var(--color-secondary);
}

.backgroundColorShader {
  background-color: var(--color-shader);
}

.backgroundColorSkeletonBase {
  background-color: var(--color-skeletonBase);
}

.backgroundColorSkeletonHighlight {
  background-color: var(--color-skeletonHighlight);
}

.backgroundColorTertiary {
  background-color: var(--color-tertiary);
}

.backgroundColorWebsiteBackground {
  background-color: var(--color-websiteBackground);
}

.backgroundColorWhite {
  background-color: var(--color-white);
}

