:root {
  --color-backgroundOverlay: rgba(0, 0, 0, 0.3);
  --color-beige: #F5F4F1;
  --color-cardBackground: #fff;
  --color-dimmer: rgba(0, 0, 0, 0.36);
  --color-floatingElement: #fff;
  --color-ghost: #c6c6c6;
  --color-menuPopup: #fff;
  --color-primary: #21201C;
  --color-red: #fd4646;
  --color-redHover: #dd1111;
  --color-secondary: #9796a5;
  --color-shader: #f5f4fe;
  --color-skeletonBase: #ebebeb;
  --color-skeletonHighlight: #f5f5f5;
  --color-tertiary: #dedde5;
  --color-websiteBackground: #F5F4F1;
  --color-white: #fff;
}
