:root {
  /* body1 */
  --font-family-body1: var(--roboto-mono), sans-serif;
  --font-size-body1: 16px;
  --font-weight-body1: 400;
  --line-height-body1: 24px;

  /* body2 */
  --font-family-body2: var(--roboto-mono), sans-serif;
  --font-size-body2: 15px;
  --font-weight-body2: 400;
  --line-height-body2: 22px;

  /* body3 */
  --font-family-body3: var(--roboto-mono), sans-serif;
  --font-size-body3: 13px;
  --font-weight-body3: 400;
  --line-height-body3: 20px;

  /* cameraName */
  --font-family-cameraName: "PP Editorial New", serif;
  --font-size-cameraName: 32px;
  --font-weight-cameraName: 200;

  /* header1 */
  --font-family-header1: "PP Editorial New", serif;
  --font-size-header1: 72px;
  --font-weight-header1: 200;

  /* header2 */
  --font-family-header2: "PP Editorial New", serif;
  --font-size-header2: 48px;
  --font-weight-header2: 200;

  /* header3 */
  --font-family-header3: var(--roboto-mono), sans-serif;
  --font-size-header3: 16px;
  --font-weight-header3: 600;

  /* navItem */
  --font-family-navItem: "PP Editorial New", serif;
  --font-size-navItem: 32px;
  --font-weight-navItem: 200;

  /* subheader */
  --font-family-subheader: var(--roboto-mono), sans-serif;
  --font-size-subheader: 20px;
  --font-weight-subheader: 400;
  --line-height-subheader: 28px;

  /* tinyLabel */
  --font-family-tinyLabel: var(--roboto-mono), sans-serif;
  --font-size-tinyLabel: 14px;
  --font-weight-tinyLabel: 700;
  --letter-spacing-tinyLabel: 0.5px;
  --line-height-tinyLabel: 20px;
}

/* TODO: finalize responsive font sizes */
@media only screen and (max-width: 768px) {
  :root {
    /* body1 */
    --font-size-body1: 15px;
    --line-height-body1: 20px;

    /* body2 */
    --font-size-body2: 12px;
    --line-height-body2: 18px;

    /* body3 */
    --font-size-body3: 10px;
    --line-height-body3: 26px;

    /* header1 */
    --font-size-header1: 36px;

    /* header2 */
    --font-size-header2: 28px;
    --line-height-header2: 34px;

    /* header3 */
    /* --font-size-header3: 16px;
    --line-height-header3: 30px; */

    /* navItem */
    --font-size-navItem: 17px;

    /* subheader */
    --font-size-subheader: 16px;
    --line-height-subheader: 22px;

    /* tinyLabel */
    --font-size-tinyLabel: 12px;
    --line-height-tinyLabel: 20px;
  }
}
