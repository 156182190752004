/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.colorBackgroundOverlay {
  color: var(--color-backgroundOverlay);
}

.colorBeige {
  color: var(--color-beige);
}

.colorCardBackground {
  color: var(--color-cardBackground);
}

.colorDimmer {
  color: var(--color-dimmer);
}

.colorFloatingElement {
  color: var(--color-floatingElement);
}

.colorGhost {
  color: var(--color-ghost);
}

.colorMenuPopup {
  color: var(--color-menuPopup);
}

.colorPrimary {
  color: var(--color-primary);
}

.colorRed {
  color: var(--color-red);
}

.colorRedHover {
  color: var(--color-redHover);
}

.colorSecondary {
  color: var(--color-secondary);
}

.colorShader {
  color: var(--color-shader);
}

.colorSkeletonBase {
  color: var(--color-skeletonBase);
}

.colorSkeletonHighlight {
  color: var(--color-skeletonHighlight);
}

.colorTertiary {
  color: var(--color-tertiary);
}

.colorWebsiteBackground {
  color: var(--color-websiteBackground);
}

.colorWhite {
  color: var(--color-white);
}

